<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>

          <h1>{{ $t("Create your own rental passport") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "With a rental passport you can easily respond to homes. To do this, you must share information about your identity and income."
              )
            }}
          </p>

          <!-- <ul class="p-0 mb-4 fw-bold">
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("nordige_step1_point1")
              }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("nordige_step1_point2")
              }}
            </li>
            <li>
              <ExclamationCircle fill="#168838" class="mr-2" />{{
                $t("nordige_step1_point3")
              }}
            </li>
          </ul> -->

          <!-- <span class="py-4" style="color: #016fff">
            {{ $t("nordigen_step1_tip") }}
          </span> -->
          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              @click="$router.push({ name: 'ExpatStep6' })"
            >
              {{ $t("Next step") }}
            </button>
            <!-- <button class="btn btn-outline-primary btn-block mt-4">
              {{ $t("Complete this later") }}
            </button> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <img src="../../../assets/images/expat/step5.svg" alt="" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
// import ExclamationCircle from "../../../components/svg/ExclamationCircle.vue";
export default {
  data() {
    return {
      step: null,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  created() {
    this.step = +this.$route.params.step;
  },
  methods: {},
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
